import { twMerge } from "tailwind-merge";
import { computed, defineComponent, onMounted, ref, useTranslations } from "#imports";
import "@sv/elements/track";
import { Button } from "package:/components/elements/Button";
import { Icon } from "package:/components/elements/Icon";
import type { Track } from "@sv/elements/track";

export enum DotsPosition {
  Inside = "inside",
  Outside = "outside",
}

interface Props {
  dotsPosition?: DotsPosition;
  variant?: "dark" | "light";
}

export const Slider = defineComponent(
  (props: Props, { slots }) => {
    const t = useTranslations();
    const track = ref<Track>();
    const current = ref(0);
    const width = ref(0);
    const position = ref(0);
    const itemCount = ref(1);
    const overflowWidth = ref(0);

    const showNext = computed(() => position.value < overflowWidth.value);
    const showPrev = computed(() => position.value > 100);
    const dotsPosition = computed(() => {  
      return props.dotsPosition ?? DotsPosition.Outside;
    });

    const progress = computed(() => {
      const value = 1 - (overflowWidth.value - position.value) / overflowWidth.value;
      return Math.min(1, Math.max(0, value));
    });

    onMounted(() => {
      track.value?.shadowRoot?.addEventListener("slotchange", (e) => {
        track.value?.moveTo(0, "none");
      });
      updateOverflow();
    });

    const move = (dir = 0) => {
      const itemWidth = (track.value?.children[0] as HTMLElement)?.offsetWidth;
      const pageItemCount = Math.round((track.value?.width || 0) / itemWidth) * dir;
      track.value?.moveBy(pageItemCount);
    };

    const next = () => {
      if (track.value && current.value >= track.value.maxIndex) {
        track.value?.setTarget([track.value.overflowWidth, 0]);
      } else {
        move(1);
      }
    };

    const prev = () => {
      move(-1);
    };

    const updateOverflow = () => {
      overflowWidth.value = track.value?.overflowWidth || 0;
      itemCount.value = track.value?.children.length || 1;
      position.value = track.value?.position.x || 0;
      width.value = track.value?.trackWidth || 0;
    };

    return () => (
      <div class="@container group relative w-full">
        <div class="relative w-full">
          <Button
            variant="purple"
            disabled={!showPrev.value}
            class={[
              "-translate-y-1/2 absolute top-1/2 z-10 hidden transform opacity-0 transition-all lg:block focus-visible:opacity-100",
              showPrev.value
                ? "group-hover:opacity-100"
                : "pointer-events-none opacity-0",
              "left-[12px]",
            ]}
            onClick={prev}
            label={t("slider.previous")}
          >
            <Icon class="block" name="arrow-left" />
          </Button>
          <Button
            variant="purple"
            disabled={!showNext.value}
            class={[
              "-translate-y-1/2 absolute top-1/2 z-10 hidden transform opacity-0 transition-all lg:block focus-visible:opacity-100",
              showNext.value
                ? "group-hover:opacity-100"
                : "pointer-events-none opacity-0",
              "right-[12px]",
            ]}
            onClick={next}
            label={t("slider.next")}
          >
            <Icon class="block" name="arrow-right" />
          </Button>

          <a-track
            ref={track}
            class="flex w-full overflow-visible"
            overflowscroll
            snap
            onScroll={() => {
              position.value = track.value?.position.x || 0;
            }}
            onChange={() => {
              current.value = track.value?.currentItem || 0;
            }}
            onFormat={(e) => {
              updateOverflow();
            }}
          >
            {slots.default?.()}
          </a-track>
        </div>

        {itemCount.value > 1 && (
          <div
            class={[
              "flex justify-center py-5 @lg:py-8",
              dotsPosition.value === DotsPosition.Inside
                ? "-translate-x-1/2 absolute bottom-0 left-1/2 w-4/5"
                : "",
            ]}
          >
            <div
              class={twMerge(
                "relative flex h-[1px] w-[200px] items-center @lg:w-[400px]",
                overflowWidth.value > 0 ? "opacity-100" : "opacity-0",
                props.variant === "dark"
                  ? "bg-[rgba(255,255,255)]/30"
                  : "bg-[#cbd2cf]/30",
              )}
              style={{
                "--value": progress.value,
              }}
            >
              <div
                class={twMerge(
                  "absolute left-[calc(var(--value)*100%-var(--value)*75px)] h-[2px] w-[75px] rounded-md transition-none",
                  props.variant === "light" ? "bg-green-800" : "bg-white",
                )}
              />
            </div>
          </div>
        )}
      </div>
    );
  },
  {
    name: "Slider",
    props: ["dotsPosition", "variant"],
  },
);
